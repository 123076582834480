import * as React from "react"
import { Seo } from "../components/seo"
import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import "../css/gallery.scss"
import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const onInit = () => {
	console.log('lightGallery has been initialized');
};

const HOOPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Home Ownership Opportunities</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<p>Through volunteer labor and donated money and materials, Habitat for Humanity Northwoods Wisconsin builds simple, affordable homes for people in need in Oneida and Vilas counties.</p>
                    <p>Future Habitat homebuyers help to build their homes through the contribution of sweat equity.  They purchase their homes financed with affordable mortgages through the USDA/Rural Development direct loan program.  We assist with the loan application process.  We also serve families in need of minor home repairs.  We  operate the Habitat Restore on Lincoln Street in Rhinelander which raises funds through the sale of gently used building materials, appliances and furniture.   Proceeds from those sales build and repair homes in our service area.</p>
					<h2>Family Selection</h2>
					<p>To partner with Habitat, potential homebuyers must first fill out an application.  Once pre-approved for financing the family selection committee then considers applicants' level of need, their willingness to provide sweat equity and their ability to repay the loan.  Habitat follows a nondiscriminatory policy of homebuyer selection.  Neither race, religion nor any of the distractions that so commonly divide us are a factor in choosing Habitat homebuyers.</p>
					<p>If you, or someone you know, is in need of decent, affordable housing, please click the images below to learn more information on the application process.</p>
					<p>Habitat for Humanity is an equal housing opportunity lender.</p>
					<h2>Building Abroad</h2>
					<p>Habitat for Humanity Northwoods Wisconsin participates in the Habitat for Humanity's tithe program in which we contribute 10% of our unrestricted revenue each year in support of Habitat's global mission.  This biblically based practice has created a continual cycle of generosity and partnership, transcending borders and languages.  The impact of tithe is woven and witnessed throughout the work of Habitat.  Collaborations and funding from the program have made an array of housing solutions possible worldwide, particularly for vulnerable populations and communities hardest hit by disaster.</p>
					<div className="gallery imgPositionsTop">
                        <LightGallery
							onInit={onInit}
							speed={500}
							plugins={[lgThumbnail, lgZoom]}
						>
							<a href="/home-ownership-opportunities.jpg">
								<img alt="Home Owners Opportunities" src="/home-ownership-opportunities.jpg" />
							</a>
							<a href="/hoo-1.jpg">
								<img alt="Home Owners Opportunities Criteria Brochure" src="/hoo-1.jpg" />
							</a>
							<a href="/hoo-2.jpg">
								<img alt="Home Owners Opportunities Criteria" src="/hoo-2.jpg" />
							</a>
						</LightGallery>
                    </div>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default HOOPage

export const Head = () => (
	<Seo
        title="About | Habitat For Humanity Northwoods Wisconsin"
    />
)
